<template>
  <div class="animated fadeIn">
    <b-card style="margin-top: 2rem;">
      <b-row slot="header">
        <b-col cols="12">
          <strong>Raise a ticket</strong>
        </b-col>
      </b-row>
      <!-- <b-row align-h="end" class="p-2">
        <div>
          
        </div>
      </b-row>-->
      <b-row>
        <form style="width: 50%; margin: auto;">
          <div class="form-group">
            <label for="firstName">Name</label>
            <input type="text" class="form-control" id="firstName" v-model="name" disabled />
            <!-- <label for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" />-->
            <label for="email">Email address</label>
            <input type="email" class="form-control" id="email" v-model="email" disabled />
          </div>
          <div class="form-group">
            <label for="issueType">Issue Type:</label>
            <select class="form-control" id="issueType">
              <option value>Select</option>
              <!-- <option
                value="JAM - Not loading or server unresponsive"
              >JAM - Not loading or server unresponsive</option>
              <option
                value="Unable to Login (Invalid Credentials)"
              >Unable to Login (Invalid Credentials)</option> -->
              <option value="Server slow for non-algo screens">Server slow for non-algo screens</option>
              <option value="Timeout Error">Timeout Error</option>
              <option value="Issue with File Upload">Issue with File Upload</option>
              <option value="Data on Screen Issue">Data on Screen Issue</option>
              <option value="Error while running the Algo">Error while running the Algo</option>
              <option value="Error while downloading Excel">Error while downloading Excel</option>
              <option value="Question on JAM screens or data">Question on JAM screens or data</option>
            </select>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea class="form-control" id="description" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label for="exampleFormControlFile1">Attach File</label>
            <!-- <b-button
              class="btn-download"
              style="margin-left: 5px;"
              @click="onUploadClick"
            >
              Upload
              <i class="fa fa-upload" style="padding-left:0.25rem;"></i>
            </b-button>-->
            <b-form-file
                style="width:100%;text-decoration: underline;"
                id="buyUploadFile"
                ref="fileToLoad"
                name="templateFile"
                v-model="file"
              />
            <!-- <input type="file" class="form-control-file" id="fileToLoad" @change="getFile" /> -->
          </div>
          <b-button size="md" class="btn-success" @click="onSubmitForm">Submit</b-button>
        </form>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  data() {
    return {
      file: null,
      fileSelector: null,
      selectedFile: null
    };
  },
  computed: {
    name() {
      return window.localStorage.getItem("name");
    },
    email() {
      return window.localStorage.getItem("emailId");
    }
  },
  methods: {
    ...mapActions({
      createTicket: "Dashboard/createTicket",
      regenerateTicket: "Dashboard/regenerateTicket"
    }),
    // onUploadClick() {
    //   if (this.fileSelector) {
    //     this.fileSelector.removeAttribute("type");
    //     this.fileSelector.removeAttribute("id");
    //   }
    //   this.fileSelector = document.createElement("input");
    //   this.fileSelector.setAttribute("type", "file");
    //   this.fileSelector.setAttribute("id", "issueFiles");
    //   var selectDialogueLink = document.createElement("a");
    //   selectDialogueLink.setAttribute("href", "");
    //   selectDialogueLink.innerText = "Select File";
    //   document.body.appendChild(this.fileSelector);
    //   this.fileSelector.click();
    //   this.fileSelector.onchange = () => {
    //     // onChangeFlag = true
    //     this.selectedFile = document.getElementById("issueFiles").files[0];
    //     console.log(this.selectedFile, '222222222222222222222222');
    //     debugger
    //   };
    // },
    getFile() {
      this.file = document.getElementById("fileToLoad").files[0];
    },
    onSubmitForm() {
      let formInput = {
        name: this.name,
        email: this.email,
        issueType: document.getElementById("issueType").value,
        description: document.getElementById("description").value,
        file: this.file
      };
      this.createTicket(formInput)
        .then(resp => {
          if (resp.status) {
            Swal.fire({
              title: 'Success',
              text: `Ticket (${resp.data.id}) has been created.`,
              // icon: "success",
              confirmButtonText: "Ok"
            });
          }
        })
        .catch(err => {
          Swal.fire({
            // title: 'Error!',
            text: err.message,
            icon: "warning",
            confirmButtonText: "Ok"
          });
        });
        //this.$gtag.config({ user_id: 'Hariprasad_new_Hello' })
        this.$gtag.event('Clicked_RaiseTicketSubmitButton', {
        'event_category': 'Raise Ticket',
        'event_label': 'Click on Submit Button',
      })
    }
  }
};
</script>

<style>
.card-header {
  padding: 0.75rem 1.25rem;
  color: #ffffff !important;
  margin-bottom: 0;
  background-color: #000000 !important;
  border-bottom: 1px solid #c8ced3;
}
.btn-success {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 100px;
}
.btn-success:hover {
  background-color: rgb(151, 221, 37) !important;
  border-color: rgb(151, 221, 37) !important;
}
</style>

